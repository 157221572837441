// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-drupal-services-mdx": () => import("./../../../src/pages/drupal-services.mdx" /* webpackChunkName: "component---src-pages-drupal-services-mdx" */),
  "component---src-pages-ecommerce-services-mdx": () => import("./../../../src/pages/ecommerce-services.mdx" /* webpackChunkName: "component---src-pages-ecommerce-services-mdx" */),
  "component---src-pages-frontend-development-mdx": () => import("./../../../src/pages/frontend-development.mdx" /* webpackChunkName: "component---src-pages-frontend-development-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-uses-mdx": () => import("./../../../src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */)
}

